import * as React from 'react';
import { css } from '@emotion/react';
import { EmptyPage } from '../assets/images';
import { changeEnableScroll } from '../utils/bridge';

const profileStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const emptyImage = css`
  margin-bottom: 1.75rem; // 15px
`;

const headingStyles = css`
  margin: 0 auto;
  font-size: 2.25rem; // 18px
  font-weight: 500;
  text-align: center;
`;

const paragraphStyles = css`
  margin-top: var(--large);
  font-size: 1.75rem;
  font-weight: 400;
  text-align: center;
  color: #a9a9a9;
  line-height: 1.6;
`;

export default function NotFoundPage() {
  React.useEffect(() => {
    changeEnableScroll(false);
  }, []);

  return (
    <section css={profileStyle}>
      <EmptyPage css={emptyImage} />
      <h1 css={headingStyles}>존재하지 않는 페이지예요 😢</h1>
      <p css={paragraphStyles}>
        최신 정보 반영에 시간이 걸릴 수 있으니
        <br />
        조금 뒤에 다시 시도해 주세요!
      </p>
    </section>
  );
}
